$(document).ready(function() {
  var cur_pos = 0;
  var lastP   = 0;

  if($( window ).width() > 528){
      str = '<video playsinline="playsinline" autoplay="autoplay" loop="true" muted="muted"><source src="'+$('#home .video').attr('data-src')+'" type="video/mp4"></video>';
      $('#home .video').html(str);
      $('#home .video').addClass('isDesktop');
  }


  $('input').iCheck({
    checkboxClass: 'icheckbox_minimal-red',
    radioClass: 'iradio_minimal-red',
    increaseArea: '20%' // optional
  });

  var owlProduct = $('.owl-product');
  owlProduct.owlCarousel({
      loop: false,
      margin: 0,
      nav: true,
      dots: true,
      navText: ['<i class="icon icon-arrow-left"><i>','<i class="icon icon-arrow-right"><i>'],
      items: 1,
      slideBy: 1,
      autoplay:false,
      stagePadding:0
  });

  owlProduct.on('changed.owl.carousel', function(event) {
      console.log(event.item.index+1);
      $('.sliderNav .col').removeClass('active');
      $('.sliderNav .col:nth-child('+(event.item.index+1)+')').addClass('active');
  });

  $("#head .mobileNav").click(function(){
      if($('#head ul').hasClass('active')){
          $("#head .mobileNav i").removeClass('icon-close').addClass('icon-menu');
          $('#head ul').removeClass('active');
          $('body').removeClass('locked');
          $('html, body').animate({
            scrollTop: lastP
          }, 0);

      }else{
          lastP = cur_pos;
          $("#head .mobileNav i").addClass('icon-close').removeClass('icon-menu');
          $('#head ul').addClass('active');
          $('body').addClass('locked');
      }
  });

  $(".accordeon h4").click(function(){
      var obj = $(this).closest('.accordeon').find('.detail-text');
      if(obj.css('display') == "none"){
          obj.slideDown( "slow" );
          $(this).closest('.accordeon').addClass('active');
      }else{
          obj.slideUp( "slow" );
          $(this).closest('.accordeon').removeClass('active');
      }
  });

  $(".accordeon .btn").click(function(){
      var obj = $(this).closest('.accordeon').find('.detail-text').find('.form');
      obj.slideDown( "slow" );
      $(this).hide();
  });



  var sections   = $('.section');
  var nav        = $('#head ul');
  var nav_height = nav.outerHeight();
  var animBool   = false;

   $(window).on('scroll', function () {
       cur_pos = $(this).scrollTop();

          sections.each(function() {
                var top    = $(this).offset().top - nav_height,
                    bottom = top + $(this).outerHeight();
                if (cur_pos >= top && cur_pos <= bottom) {
                    ids = $(this).attr('id');

                    nav.find('li').removeClass('active');
                    nav.find('a[href="#'+$(this).attr('id')+'"]').closest('li').addClass('active');
                }
          });
    });

  var didScroll;
  var lastScrollTop = 0;
  var delta = 200;
  var navbarHeight = $('#head').outerHeight();
  var scrollTop = $(this).scrollTop();


  if(scrollTop > 20){
      $('#head').addClass('fixed');
  }

  $(window).scroll(function(event){
       didScroll = true;
  });

  setInterval(function() {
       if (didScroll) {
           hasScrolled();
           didScroll = false;
       }
   }, 500);

   function hasScrolled() {
       var scrollTop = $(this).scrollTop();

       if(scrollTop > 20){
           if(!$('#head').hasClass('fixed')){
                $('#head').addClass('fixed');
           }
       }else{
           if($('#head').hasClass('fixed')){
               $('#head').removeClass('fixed');
           }
       }
   }


   $('.jsLink').click(function(){
       window.location=$(this).find("a").attr("href");
       return false;
   });

   $('.btn-scroll').click(function(){
       $('#head ul li a[href="'+$(this).attr('href')+'"]').trigger( "click" );

       return false;
   })

   $('#head ul,#footer ul').find('a').on('click', function () {
     var $el = $(this)
       , id = $el.attr('href');

     if(id == '#pribehy'){
           $('#reference .btns a').trigger( "click" );
           id = '#reference';
     }

     if($('#head ul').hasClass('active')){
         $('body').removeClass('locked');
          $("#head .mobileNav i").removeClass('icon-close').addClass('icon-menu');
         $('#head ul').removeClass('active');
     }

     $('html, body').animate({
       scrollTop: $(id).offset().top + 5  - 76
     }, 500);

     return false;
   });

});
